import { Brand, BrandCode } from "core/entities";
// import { FixNetworkWorldTheme } from "core/theme/FixNetworkWorldTheme";
import { SpeedyAutoThemeOptions } from "core/theme/SpeedyAutoTheme";
import { NovusGlassThemeOptions } from "core/theme/NovusGlassTheme";
import { SpeedyGlassThemeOptions } from "core/theme/SpeedyGlassTheme";
import { FixAutoThemeOptions } from "core/theme/FixAutoTheme";
import { ProColorThemeOptions } from "core/theme/ProColorTheme";
import { CREThemeOptions } from "core/theme/CRETheme";

const BrandMap = new Map<BrandCode, Brand>();

BrandMap.set("novusglass", {
  brandClassification: "Glass",
  brandCode: "novusglass",
  themeOptions: NovusGlassThemeOptions,
  name: "Novus Auto Glass",
});

BrandMap.set("speedyauto", {
  brandClassification: "Mechanical",
  brandCode: "speedyauto",
  themeOptions: SpeedyAutoThemeOptions,
  name: "Speedy Auto",
});

BrandMap.set("speedyglass", {
  brandClassification: "Glass",
  brandCode: "speedyglass",
  themeOptions: SpeedyGlassThemeOptions,
  name: "Speedy Glass",
});

BrandMap.set("fixaut", {
  brandClassification: "Collision",
  brandCode: "fixaut",
  themeOptions: FixAutoThemeOptions,
  name: "Fix Auto",
});

BrandMap.set("proc", {
  brandClassification: "Collision",
  brandCode: "proc",
  themeOptions: ProColorThemeOptions,
  name: "Pro Color",
});

BrandMap.set("cre", {
  brandClassification: "Collision",
  brandCode: "cre",
  themeOptions: CREThemeOptions,
  name: "Collision Repair Experts",
});

export default BrandMap;
