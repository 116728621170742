import { ContextValue } from "core/entities/permissionContextDefs";

/**
 * @param {ContextValue[]} currentContext - Context of the user.
 * @param {ContextValue[]} permissionContext - Context to test against
 * @returns {boolean} Whether the permissionContext includes the currentContext or not
 */
export function isSameContext(
  currentContext: ContextValue[],
  permissionContext: ContextValue[]
): boolean {
  const permissionContextMap = new Map(
    permissionContext.map(({ key, value }) => [key, value])
  );
  return currentContext.every((curr) => {
    const permContextValue = permissionContextMap.get(curr.key);
    if (!permContextValue) {
      return false;
    }
    return curr.value.toLowerCase() === permContextValue.toLowerCase();
  });
}
