import {
  AttachmentsObject,
  DocDocumentAttachmentCreateUpdateDto,
  DocDocumentCreateUpdateDto,
  NewDocumentFormValues,
} from "../../entities";
import { formStateToPermissionSet } from "../PermissionManagement/ViewablePermissionsManagement/utils";

function processAttachments(
  attachments: AttachmentsObject | undefined,
  isDocumentUpload: boolean
): DocDocumentAttachmentCreateUpdateDto[] {
  if (!attachments) {
    return [];
  }
  const values = Object.values(attachments);
  if (isDocumentUpload) {
    const documentAttachments = values.filter((entry) => entry.documentLink);
    const createUpdateDtos: DocDocumentAttachmentCreateUpdateDto[] =
      documentAttachments.map((attachment) => ({
        transKey: attachment.transKey ?? "en",
        documentLink: attachment.documentLink,
        thumbnailId: attachment.thumbnailId || undefined,
        thumbnailName: attachment.thumbnailName || undefined,
      }));
    return createUpdateDtos;
  }
  const fileAttachments = values.filter(
    (entry) => entry.fileId && entry.fileName
  );
  const createUpdateDtos: DocDocumentAttachmentCreateUpdateDto[] =
    fileAttachments.map((attachment) => ({
      transKey: attachment.transKey ?? "en",
      fileId: attachment.fileId,
      fileName: attachment.fileName,
      thumbnailId: attachment.thumbnailId || undefined,
      thumbnailName: attachment.thumbnailName || undefined,
    }));
  return createUpdateDtos;
}

export function processNewDocumentFormValues(
  {
    availableFrom,
    availableTo,
    isDocumentUpload,
    permissions: formPermissions,
    translations: translationsObject,
    attachments: attachmentsObject,
  }: NewDocumentFormValues,
  parentId: string
): DocDocumentCreateUpdateDto {
  const attachments = processAttachments(attachmentsObject, isDocumentUpload);
  const permissions = formStateToPermissionSet(formPermissions);
  const translationEntries = Object.values(translationsObject);
  const translations: DocDocumentCreateUpdateDto["translations"] =
    translationEntries
      .filter(
        (entry): entry is typeof entry & { name: string } =>
          typeof entry.name === "string"
      )
      .map((entry) => ({
        name: entry.name,
        description: entry.description || undefined,
        id: entry.id || undefined,
        isDefault: entry.isDefault || undefined,
        tags: entry.tags || undefined,
        transKey: entry.transKey || "en",
      }));

  const submitData: DocDocumentCreateUpdateDto = {
    availableFrom: availableFrom?.toUTCString() || undefined,
    availableTo: availableTo?.toUTCString() || undefined,
    permissions,
    attachments,
    translations,
    parentId,
  };
  return submitData;
}
