import { useState, ReactNode } from "react";
import { ApplicationErrorBoundaryPage } from "core/pages/ApplicationErrorBoundaryPage";
import { usePermissionContext } from "core/hooks/usePermissionContext";
import { useMenuItems } from "core/hooks/useMenuItems";
import { useDebounce } from "core/hooks/useDebounce";
import { useToken } from "core/hooks/useToken";
import { ApplicationLoadingDisplay } from "../ApplicationLoadingDisplay";

interface GlobalLoaderProps {
  children: ReactNode;
}
export function MainLoader({ children }: GlobalLoaderProps) {
  const {
    data: permissionContextData,
    isLoading: isPermissionContextLoading,
    isError: permissionContextError,
  } = usePermissionContext();

  const { isLoading: isMenuItemsLoading, isError: menuItemsError } =
    useMenuItems();

  const isError =
    permissionContextError || (permissionContextError && menuItemsError);

  const [isLoading, setIsLoading] = useState(
    !permissionContextData || isPermissionContextLoading || isMenuItemsLoading
  );

  // using debounce to prevent multiple visible loading state changes
  useDebounce(
    () => {
      setIsLoading(
        !permissionContextData ||
          isPermissionContextLoading ||
          isMenuItemsLoading
      );
    },
    [permissionContextData, isPermissionContextLoading, isMenuItemsLoading],
    500
  );

  if (isError) {
    return <ApplicationErrorBoundaryPage />;
  }
  if (isLoading) {
    return <ApplicationLoadingDisplay />;
  }

  return <>{children}</>;
}

function TokenLoader({ children }: { children: ReactNode }) {
  const token = useToken();
  if (!token) {
    return <ApplicationLoadingDisplay />;
  }
  return <>{children}</>;
}

export function GlobalLoader({ children }: { children: ReactNode }) {
  return (
    <TokenLoader>
      <MainLoader>{children}</MainLoader>
    </TokenLoader>
  );
}
