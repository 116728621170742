import { PermissionContext } from "core/entities";

export function extractRegionsForBrand(
  permissionContexts: PermissionContext[],
  brandCode: string
): string[] {
  return permissionContexts.reduce((acc, { context: contextList }) => {
    const isCurrentBrand = contextList.some(
      ({ key, value }) =>
        key.toLowerCase() === "brand" &&
        value.toLowerCase() === brandCode.toLowerCase()
    );
    if (!isCurrentBrand) {
      return acc;
    }
    const region = contextList.find(
      ({ key }) => key.toLowerCase() === "region"
    )?.value;
    const regionCode = region?.slice(-2);

    if (regionCode && !acc.includes(regionCode)) {
      acc.push(regionCode);
    }
    return acc;
  }, [] as string[]);
}
