import getYear from "date-fns/getYear";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { NotificationDot } from "features/Notifications/NotificationDot";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import { useBrandName } from "core/hooks/useBrandName";
import { useMobileMenuItems } from "../MobileNav/useMobileMenuItems";
import { JoinChildren } from "../JoinChildren";
import { Link } from "../Link";

export function FooterLegal() {
  const brandName = useBrandName();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:FooterLegal",
  });
  const { isMobile } = useBreakpoints();
  const { menuItems } = useMobileMenuItems("Legal");
  const linkProps = {
    variant: "caption",
    textAlign: "center",
    color: "primary.contrastText",
  } as const;
  return (
    <Stack py={1} gap={1} color="primary.contrastText" bgcolor="primary.main">
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <JoinChildren JoinElement={<Typography {...linkProps}>|</Typography>}>
          {Array.isArray(menuItems) &&
            menuItems.map(({ displayName, id, url, target }) =>
              url ? (
                <Link
                  key={id}
                  {...linkProps}
                  href={url}
                  target={target ?? undefined}
                >
                  {displayName} <NotificationDot id={id} mode="self" />
                </Link>
              ) : (
                <Typography key={id} {...linkProps}>
                  {displayName}
                </Typography>
              )
            )}
          {!isMobile ? (
            <Typography {...linkProps}>
              {t("copyright", {
                year: getYear(new Date()),
                brandName,
              })}
            </Typography>
          ) : null}
        </JoinChildren>
      </Stack>
      {isMobile && (
        <Typography {...linkProps}>
          {t("copyright", {
            year: getYear(new Date()),
            brandName,
          })}
        </Typography>
      )}
    </Stack>
  );
}
