import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { useAPIAll } from "core/hooks/useAPIAll";
import { useRouter } from "next/router";
import Skeleton from "@mui/material/Skeleton";
import type { GenericAPIData } from "core/entities";
import { components, paths } from "resources/api-schema";
import { useBrand } from "core/hooks/useBrand";
import { useRegion } from "core/hooks/useRegion";
import { GridAreaContainer } from "../GridAreaContainer";
import { Carrousel, CarrouselImage } from "../Carrousel";
import { ErrorComponent } from "../ErrorComponent";

type PublicAdvertisementBanner =
  components["schemas"]["Fixhub.AdvertisementBanners.PublicAdvertisementBannerDto"];

type AdvertisementQuery =
  paths["/api/app/advertisement-banner/public-list"]["get"]["parameters"]["query"];

export function AdvertisementWidget() {
  const { locale } = useRouter();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:AdvertisementWidget",
  });
  const { brandCode } = useBrand();
  const region = useRegion();
  const params: AdvertisementQuery = {
    Locale: locale!,
    BrandShortCode: brandCode,
    IsoAlpha2Code: region,
  };
  const { data, isError, isLoading } = useAPIAll<
    GenericAPIData<PublicAdvertisementBanner>
  >({
    path: "/api/app/advertisement-banner/public-list",
    params,
  });
  if (isLoading) {
    return (
      <GridAreaContainer gridArea="ads" title={t("gridAreaContainer")}>
        <Skeleton variant="rectangular" height={400} />
      </GridAreaContainer>
    );
  }
  if (isError) {
    return (
      <GridAreaContainer gridArea="ads" title={t("gridAreaContainer")}>
        <Paper sx={{ p: 2 }}>
          <ErrorComponent apiError={isError} />
        </Paper>
      </GridAreaContainer>
    );
  }
  const images: CarrouselImage[] =
    data?.items.filter((entry): entry is CarrouselImage =>
      Boolean(entry.id && entry.imageSrc && entry.altText && entry.title)
    ) ?? [];

  if (images.length === 0) {
    return null;
  }

  return (
    <GridAreaContainer gridArea="ads" title={t("gridAreaContainer")}>
      <Carrousel
        images={images}
        intervalInMilliseconds={6000}
        paperProps={{
          sx: {
            aspectRatio: "3 / 1",
            height: "auto",
            maxHeight: "calc(100vh - 250px)",
          },
        }}
      />
    </GridAreaContainer>
  );
}
