import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMenuItems } from "core/hooks/useMenuItems";
import { NewDocumentFormValues } from "features/DMS/entities";
import {
  useDMSFormErrorHandler,
  useInfiniteDocuments,
} from "features/DMS/hooks";
import { usePromiseCallbackTracking } from "core/hooks/usePromiseCallbackTracking";
import { usePostJsonToCentrix } from "core/hooks/usePostJsonToCentrix";
import { useViewableDMSPermissionOptions } from "../PermissionManagement";
import { DocumentForm, defaultValues } from "./DocumentForm";
import { processNewDocumentFormValues } from "./processNewDocumentFormValues";

interface DocumentAddProps {
  parentId: string;
  docLibraryId: string;
  cancelOnClick: () => void;
  canManageLibraryContents: boolean;
}

export function DocumentAdd({
  cancelOnClick,
  parentId,
  docLibraryId,
  canManageLibraryContents,
}: DocumentAddProps) {
  const formMethods = useForm<NewDocumentFormValues>({
    defaultValues,
    mode: "onChange",
  });

  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DocumentAdd",
  });
  const apiPostJSON = usePostJsonToCentrix();
  const { mutate: mutateManageableList } = useInfiniteDocuments({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const { mutate: mutateViewableList } = useInfiniteDocuments({
    parentId,
    mode: "view",
    canManageLibraryContents,
  });
  const { isLoading } = useViewableDMSPermissionOptions(docLibraryId);
  const { mutate: mutateMenuItems } = useMenuItems();
  const { handleSubmit } = formMethods;
  const { errorHandler } = useDMSFormErrorHandler(formMethods, "DocumentForm");

  const postDmsDocument = usePromiseCallbackTracking(
    async (data: NewDocumentFormValues) => {
      const submitData = processNewDocumentFormValues(data, parentId);
      const createdDmsDocument = await apiPostJSON({
        path: "/api/app/doc-document",
        body: submitData,
      });
      return createdDmsDocument;
    },
    [apiPostJSON, parentId],
    {
      componentName: "DocumentAdd",
      functionName: "onValidSubmit",
      successMessage: t("POSTSuccess"),
      errorMessage: t("POSTError"),
    }
  );

  async function onValidSubmit(data: NewDocumentFormValues) {
    const newDocument = await postDmsDocument(data);
    if (!newDocument) {
      return;
    }

    mutateManageableList((currentResponses) => {
      const lastResponse = currentResponses?.at(-1);
      if (!currentResponses || !lastResponse) {
        return [{ items: [newDocument], totalCount: 1 }];
      }
      if (Array.isArray(lastResponse?.items)) {
        const oldItems = lastResponse.items;
        const newTotalCount = lastResponse.totalCount + 1;
        const newItems = [...oldItems, newDocument];
        return currentResponses.map((res, index, { length }) =>
          index === length - 1
            ? { items: newItems, totalCount: newTotalCount }
            : res
        );
      }
      return [{ items: [newDocument], totalCount: 1 }];
    });
    mutateViewableList();
    mutateMenuItems();
    cancelOnClick();
  }

  const onSubmit = handleSubmit(onValidSubmit, errorHandler);

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }

  return (
    <FormProvider {...formMethods}>
      <DocumentForm
        isLoading={isLoading}
        componentName="DocumentAdd"
        formMethods={formMethods}
        onSubmit={onSubmit}
        clearOnClick={clearOnClick}
        cancelOnClick={cancelOnClick}
        docLibraryId={docLibraryId}
      />
    </FormProvider>
  );
}
