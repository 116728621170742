import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { NotificationBadge } from "features/Notifications/NotificationBadge";
import { Link } from "../Link";
import type { RootMenuItem } from "../MobileNav/defs";
import { useMobileMenuItems } from "../MobileNav/useMobileMenuItems";

export function FooterButtons() {
  const { menuItems, isLoading } = useMobileMenuItems("FooterButton");
  if (isLoading) {
    return null;
  }
  return (
    <Stack spacing={2}>
      {Array.isArray(menuItems) &&
        menuItems
          .filter((item): item is RootMenuItem & { url: string } =>
            Boolean(item.url)
          )
          .map(({ id, displayName, url }) =>
            url ? (
              <NotificationBadge
                sx={{ maxWidth: "300px" }}
                key={id}
                id={id}
                mode="self"
              >
                <Button
                  key={id}
                  href={url}
                  LinkComponent={Link}
                  size="small"
                  variant="contained"
                  sx={{ width: "100%" }}
                >
                  {displayName}
                </Button>
              </NotificationBadge>
            ) : (
              <Button
                key={id}
                size="small"
                variant="contained"
                sx={{ width: "100%", maxWidth: "300px" }}
              >
                {displayName}
              </Button>
            )
          )}
    </Stack>
  );
}
