import { useTranslation } from "react-i18next";
import { emailRegex } from "core/utils/emailRegex";
import { FieldValues } from "react-hook-form";
import {
  ReactHookTextField,
  ReactHookTextFieldProps,
} from "./ReactHookTextField";

export function ReactHookEmailInput<
  T extends FieldValues = FieldValues,
  C = any,
>({ ...props }: ReactHookTextFieldProps<T, C>) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:ReactHookEmailInput",
  });
  return (
    <ReactHookTextField
      autoComplete="email"
      rules={{
        pattern: {
          message: t("invalidEmail"),
          value: emailRegex,
        },
      }}
      {...props}
    />
  );
}
