import type { paths } from "resources/api-schema";
import { ExtractSuccessType, ParametersProperty } from "core/entities/APITypes";
import useSWR from "swr";
import { APIError } from "core/entities";
import { buildCentrixUrl } from "../utils/buildCentrixUrl";
import { useJsonFetcherWithHeaders } from "./useJsonFetcherWithHeaders";

export type UseCentrixApiArg<T extends keyof paths> = {
  path: T;
  swrOptions?: Parameters<
    typeof useSWR<ExtractSuccessType<T, "get">, APIError>
  >[2];
  shouldFetch?: boolean;
} & ParametersProperty<T, "get">;

export function useCentrixApi<T extends keyof paths>({
  path,
  parameters,
  swrOptions,
  shouldFetch = true,
}: UseCentrixApiArg<T>) {
  const fetcher = useJsonFetcherWithHeaders();
  const url = buildCentrixUrl({ path, parameters });
  const { data, error, isLoading, mutate, isValidating } = useSWR<
    ExtractSuccessType<T, "get">,
    APIError
  >(shouldFetch ? url : null, fetcher, swrOptions);

  return {
    data,
    isLoading,
    isError: error,
    isValidating,
    mutate,
  };
}
