import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { SUPPORT_EMAIL } from "config";
import { PageHeader } from "core/components/PageHeader";
import { useBrand } from "core/hooks/useBrand";
import { usePermissionContext } from "core/hooks/usePermissionContext";
import { useRegion } from "core/hooks/useRegion";
import Link from "next/link";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "react-i18next";
import { UrlObject } from "url";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { usePreferredRegion } from "core/hooks/usePreferredRegion";
import { RegionCode } from "brandRegionConfig";
import { extractRegionsForBrand } from "./extractRegionsForBrand";

export function UnauthorizedContextPage() {
  const currentRegion = useRegion();
  const { brandCode } = useBrand();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:UnauthorizedContextPage",
  });
  const { t: regionT } = useTranslation("Fixhub", {
    keyPrefix: "Region:RegionName",
  });
  const subTitle = currentRegion
    ? t("region", { region: regionT(currentRegion) })
    : undefined;
  const { value: preferredRegion, setValue: setPreferredRegion } =
    usePreferredRegion();

  const { locale, pathname, query } = useRouter();
  const { data } = usePermissionContext();
  const brandRegions = extractRegionsForBrand(data ?? [], brandCode);
  const regionOptions: {
    label: string;
    regionCode: RegionCode;
    href: UrlObject;
  }[] = brandRegions.map((region) => ({
    label: regionT(region),
    regionCode: region as RegionCode,
    href: {
      pathname: `/${locale}${pathname}`,
      query: { ...query, region },
    },
  }));

  // If the preferred region (which defaults to "ca" for new users) is not available for this user,
  // the region selected will become the preferred region.
  const invalidPreferredRegion = !brandRegions.includes(preferredRegion);

  const handleRegionChange = (region: RegionCode) => {
    if (invalidPreferredRegion) {
      setPreferredRegion(region);
    }
  };

  return (
    <Box component="main">
      <PageHeader
        title={t("pageTitle")}
        titleTypographyProps={{ color: "error.main" }}
        subTitle={subTitle}
        subTitleTypographyProps={{ color: "error.main", variant: "h6" }}
      />

      {regionOptions.length > 0 && (
        <>
          <Typography variant="subtitle1" fontWeight="bold">
            {t("availableRegionsMessage")}
          </Typography>
          <Stack spacing={2} my={2}>
            {regionOptions.map(({ href, label, regionCode }) => (
              <Link passHref key={label} href={href}>
                <Button
                  sx={{ width: "100%" }}
                  variant="outlined"
                  onClick={() => handleRegionChange(regionCode)}
                >
                  {label}
                </Button>
              </Link>
            ))}
          </Stack>
          {invalidPreferredRegion && (
            <Typography color="text.secondary">
              {t("invalidPreferredRegionMessage")}
            </Typography>
          )}
        </>
      )}
      <Typography my={4}>
        <Trans i18nKey="Next:Core:UnauthorizedContextPage.message">
          Something isn&apos;t right? Please contact your supervisor or
          <MuiLink
            color="primary.main"
            fontWeight="bold"
            underline="none"
            href={`mailto: ${SUPPORT_EMAIL}`}
          >
            {SUPPORT_EMAIL}
          </MuiLink>
        </Trans>
      </Typography>
    </Box>
  );
}
