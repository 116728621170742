import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useMenuItems } from "core/hooks/useMenuItems";
import { MenuItemDto } from "core/entities";
import { Link } from "../Link";
import { NavCategoryLinks } from "./NavCategoryLinks";
import { LibraryLinks } from "./LibraryLinks";

export const testIds = {
  showAllLink: "menu-dropdown-content_show-all-link",
};

export type NavSectionCategoriesProps = {
  submenus: MenuItemDto[];
  libraries: ReturnType<typeof useMenuItems>["menuItems"][number]["libraries"];
  displayName: string;
  sectionId: string;
};

export function NavSectionCategories({
  submenus,
  libraries = [],
  displayName,
  sectionId,
}: NavSectionCategoriesProps) {
  const { t } = useTranslation("Fixhub");
  const areLibraryLinksVisible = libraries.length > 0;
  const categoriesContainingLinks =
    submenus?.filter((category) => category.submenus?.length) || [];

  const numberOfCategoriesToDisplay = areLibraryLinksVisible ? 4 : 5;
  const visibleCategories = categoriesContainingLinks.slice(
    0,
    numberOfCategoriesToDisplay
  );
  const numberOfInitiallyVisibleLinks = 8;

  if (!areLibraryLinksVisible && categoriesContainingLinks.length === 0) {
    return null;
  }

  return (
    <Box component="section" px={2} py={1}>
      <Stack direction="row">
        <Stack direction="row" spacing={4}>
          {libraries.length > 0 && (
            <LibraryLinks
              libraries={libraries}
              numberOfInitiallyVisibleLinks={numberOfInitiallyVisibleLinks}
            />
          )}
          {visibleCategories.map(
            ({
              id: categoryId,
              displayName: categoryDisplayName,
              url: categoryHref,
              target: categoryTarget,
              submenus: categorySubMenus,
            }) =>
              categoryDisplayName ? (
                <NavCategoryLinks
                  id={categoryId!}
                  numberOfInitiallyVisibleLinks={numberOfInitiallyVisibleLinks}
                  key={categoryId}
                  href={categoryHref ?? undefined}
                  target={categoryTarget ?? undefined}
                  submenus={categorySubMenus ?? []}
                  displayName={categoryDisplayName}
                />
              ) : null
          )}
        </Stack>
      </Stack>
      {categoriesContainingLinks.length > numberOfCategoriesToDisplay && (
        <Stack alignItems="end" p={3}>
          <Link
            passHref
            href={`/links/${sectionId}`}
            data-testid={testIds.showAllLink}
          >
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              sx={{
                "&:hover": {
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                },
                backgroundColor: "primary.light",
                color: "primary.contrastText",
              }}
            >
              {t("Next:Core:SeeAllButtonText", { category: displayName })}
            </Button>
          </Link>
        </Stack>
      )}
    </Box>
  );
}
