import DeleteIcon from "@mui/icons-material/Delete";
import Popover from "@mui/material/Popover";
import SettingsIcon from "@mui/icons-material/Settings";
import MarkReadIcon from "@mui/icons-material/Drafts";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Link } from "core/components/Link";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import Drawer from "@mui/material/Drawer";
import { FixedSizeList } from "react-window";
import Box from "@mui/material/Box";
import { ErrorComponent } from "core/components/ErrorComponent";
import LinearProgress from "@mui/material/LinearProgress";
import { useNotifications } from "./useNotifications";
import { NotificationListItem } from "./NotificationListItem";
import { Notification } from "./NotificationDefs";

function VirtualizedRow({
  index,
  style,
  data: { onClose, notifications },
}: {
  index: number;
  style: any;
  data: { onClose: () => Promise<void>; notifications: Notification[] };
}) {
  const notification = notifications[index]!;
  return (
    <NotificationListItem
      closeMenu={onClose}
      {...notification}
      listItemProps={{ sx: style }}
    />
  );
}

function NotificationMenuContent({
  onClose,
}: {
  onClose: () => Promise<void>;
}) {
  const {
    notifications,
    markAllNotificationsAsRead,
    deleteAllNotifications,
    isLoading,
    isError,
  } = useNotifications();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:NotificationsMenu",
  });
  if (isLoading) {
    return (
      <Box height={500} p={4}>
        <LinearProgress />
      </Box>
    );
  }
  if (isError) {
    return (
      <Box height={500} p={2}>
        <ErrorComponent variant="h5" apiError={isError} />
      </Box>
    );
  }
  return (
    <>
      <Stack
        px={2}
        pt={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography variant="subtitle2">{t("title")}</Typography>
        <Stack direction="row" gap={1}>
          <Button
            size="small"
            onClick={markAllNotificationsAsRead}
            startIcon={<MarkReadIcon />}
          >
            {t("markAllAsRead")}
          </Button>
          <Button
            size="small"
            onClick={deleteAllNotifications}
            startIcon={<DeleteIcon />}
          >
            {t("deleteAll")}
          </Button>
          <IconButton
            color="primary"
            LinkComponent={Link}
            onClick={onClose}
            href="/user-preferences/notifications"
            size="small"
          >
            <SettingsIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1 }} />
      {notifications.length === 0 ? (
        <Box p={2} height={500}>
          <Typography color="text.secondary">{t("noNotifications")}</Typography>
        </Box>
      ) : (
        <FixedSizeList
          height={500}
          width="100%"
          itemSize={56}
          itemData={{ notifications, onClose }}
          itemCount={notifications.length}
          overscanCount={10}
        >
          {VirtualizedRow}
        </FixedSizeList>
      )}
    </>
  );
}
export interface NotificationMenuProps {
  anchorEl: HTMLButtonElement | null;
  isOpen: boolean;
  onClose: () => Promise<void>;
}

export function NotificationsMenu({
  anchorEl,
  isOpen,
  onClose,
}: NotificationMenuProps) {
  const { isDesktop } = useBreakpoints();
  if (!isDesktop) {
    return (
      <Drawer onClose={onClose} open={isOpen} anchor="bottom">
        <NotificationMenuContent onClose={onClose} />
      </Drawer>
    );
  }
  return (
    <Popover
      slotProps={{ paper: { sx: { width: 400, overflow: "hidden" } } }}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <NotificationMenuContent onClose={onClose} />
    </Popover>
  );
}
