import type { ContextValue } from "core/entities/permissionContextDefs";
import { useMemo, createContext, ReactNode } from "react";
import { useBrandRegionCode } from "core/hooks/useBrandRegionCode";
import { getBrandCode } from "core/utils/getBrandCode";

export const CurrentBrandRegionContext = createContext<ContextValue[]>([]);
interface UserContextProviderProps {
  children: ReactNode;
}

export default function CurrentBrandRegionContextProvider({
  children,
}: UserContextProviderProps) {
  const brand = getBrandCode();
  const brandRegionCode = useBrandRegionCode();
  const userContextValue: ContextValue[] = useMemo(() => {
    if (!brand || !brandRegionCode) {
      return [];
    }
    return [
      { key: "brand", value: brand },
      { key: "region", value: brandRegionCode },
    ];
  }, [brand, brandRegionCode]);

  return (
    <CurrentBrandRegionContext.Provider value={userContextValue}>
      {children}
    </CurrentBrandRegionContext.Provider>
  );
}
