import { ReactNode } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  isDevelopment,
  APPLICATIONINSIGHTS_CONNECTION_STRING,
  API_URL,
  IDS_URL,
  CENTRIX_URL,
  GOOGLE_EMBED_API_KEY,
  NPS_REDIRECT_URL,
  V7_REDIRECT_URL,
  NOVUSGLASS_REDIRECT_URL,
  SPEEDYAUTO_REDIRECT_URL,
  FIXAUTO_REDIRECT_URL,
  NPS_CRE_REDIRECT_URL,
  NPS_FIXAUTO_REDIRECT_URL,
  SUPPORT_EMAIL,
  MUI_X_LICENSE,
} from "config";

const environmentVariables: Record<string, string | undefined> = {
  API_URL,
  IDS_URL,
  CENTRIX_URL,
  APPLICATIONINSIGHTS_CONNECTION_STRING,
  GOOGLE_EMBED_API_KEY,
  NPS_REDIRECT_URL,
  V7_REDIRECT_URL,
  SUPPORT_EMAIL,
  NOVUSGLASS_REDIRECT_URL,
  SPEEDYAUTO_REDIRECT_URL,
  FIXAUTO_REDIRECT_URL,
  NPS_CRE_REDIRECT_URL,
  NPS_FIXAUTO_REDIRECT_URL,
  MUI_X_LICENSE,
};

const missingConfigs = Object.entries(environmentVariables)
  .filter(
    (keyValuePair): keyValuePair is [string, undefined] =>
      keyValuePair[1] === undefined
  )
  .map(([key]) => key);

export function ConfigCheckWrapper({ children }: { children: ReactNode }) {
  if (isDevelopment) {
    return <>{children}</>;
  }
  if (missingConfigs.length === 0) {
    return <>{children}</>;
  }
  return (
    <Container maxWidth="lg">
      <Box component="main" paddingY={2}>
        <Typography variant="h4" color="error.main">
          The following environment variable have not been configured:
        </Typography>
        <ul>
          {missingConfigs.map((variable) => (
            <li key={variable}>
              <strong>{variable}</strong>
            </li>
          ))}
        </ul>
      </Box>
    </Container>
  );
}
