import { IDS_URL } from "config";
import { useExtensoToken } from "core/hooks/useExtensoToken";
import { useRouter } from "next/router";
import { AuthProvider, AuthProviderProps, useAuth } from "oidc-react";
import { ReactNode, useEffect } from "react";
import { AvailableRoutes } from "resources/availableRoutes";
import { useNavigate } from "core/hooks/useNavigate";
import { ApplicationLoadingDisplay } from "./ApplicationLoadingDisplay";

export const POST_LOGIN_PATH_SESSION_STORAGE_KEY = "post-login-path";
const excludedPaths: AvailableRoutes[] = ["/signin-oidc", "/logout"];

function shouldSaveRedirectPath(routerPathname: AvailableRoutes) {
  return !excludedPaths.includes(routerPathname);
}

function AuthLoader({
  children,
  bypass = false,
}: {
  children: ReactNode;
  bypass: boolean;
}) {
  const { userData, userManager } = useAuth();
  useEffect(
    () =>
      userManager.events.addUserLoaded((data) => {
        const { access_token: accessToken, expires_at: expiresAt } = data;

        if (!expiresAt) {
          document.cookie = `authorization_token=${accessToken};path=/;SameSite=strict;`;
          return;
        }

        const expires = new Date(expiresAt * 1000).toUTCString();
        document.cookie = `authorization_token=${accessToken};path=/;SameSite=strict;expires=${expires}`;
      }),
    [userManager.events]
  );
  if (bypass) {
    return <>{children}</>;
  }
  if (!userData) {
    return <ApplicationLoadingDisplay />;
  }
  return <>{children}</>;
}

export default function Authorize({ children }: { children: ReactNode }) {
  const { query, asPath, pathname, replace } = useRouter();
  const navigate = useNavigate(false);
  const { token: extensoToken } = useExtensoToken();

  const logoutPathName: AvailableRoutes = "/logout";
  const isLogoutPage = pathname === logoutPathName;

  const extensoRedirectPathname: AvailableRoutes = "/extenso-redirect";
  const isExtensoRedirectPage = pathname === extensoRedirectPathname;

  const isFromExtenso = isExtensoRedirectPage || Boolean(extensoToken);

  let autoSignIn = true;

  if (isFromExtenso || isLogoutPage) {
    autoSignIn = false;
  }

  const configWithRedirects: AuthProviderProps = {
    authority: IDS_URL,
    clientId: "Next_App",
    scope: "openid profile Fixhub",
    responseType: "code",
    redirectUri: `${window.location.origin}/signin-oidc`,
    postLogoutRedirectUri: `${window.location.origin}/logout`,
    autoSignIn,
    onBeforeSignIn() {
      if (shouldSaveRedirectPath(pathname as AvailableRoutes)) {
        const path =
          query.maintain_locale === "true" ? window.location.pathname : asPath;
        sessionStorage.setItem(POST_LOGIN_PATH_SESSION_STORAGE_KEY, path);
        return path;
      }
      return "/";
    },
    async onSignIn() {
      const postLoginPath = sessionStorage.getItem(
        POST_LOGIN_PATH_SESSION_STORAGE_KEY
      );
      if (postLoginPath && postLoginPath !== "/") {
        await replace(postLoginPath);
        return;
      }
      await navigate("/");
    },
  };

  return (
    <AuthProvider {...configWithRedirects}>
      <AuthLoader bypass={!autoSignIn}>{children}</AuthLoader>
    </AuthProvider>
  );
}
