import { useUserSetting } from "./useUserSetting";
import { useBrand } from "./useBrand";
import { useAvailableRegions } from "./useAvailableRegions";

export function usePreferredRegion() {
  const availableRegions = useAvailableRegions();
  const { brandCode } = useBrand();
  const defaultRegion = availableRegions.includes("ca")
    ? "ca"
    : availableRegions[0];

  if (!defaultRegion) {
    throw new Error(
      `no "availableRegions" configured for the brand ${brandCode}`
    );
  }

  return useUserSetting("region", defaultRegion, {
    brand: true,
  });
}
