import { HTMLAttributeAnchorTarget, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { NotificationDot } from "features/Notifications/NotificationDot";
import { Link } from "../Link";
import { CategoryMenuItem, MenuLibraryItem } from "./defs";
import { MobileNavCategoryMenuItem } from "./MobileNavCategoryMenuItem";
import { ExpandButton } from "./ExpandButton";

const TEST_ID = "mobile-nav-root-menu-item";

interface MobileNavLibraryListItemProps {
  id: string;
  displayName: string;
}
function MobileNavLibraryListItem({
  displayName,
  id,
}: MobileNavLibraryListItemProps) {
  return (
    <ListItem disablePadding key={id}>
      <ListItemButton
        sx={{ pl: 6 }}
        component={Link}
        href={`/dms/library/${id}`}
      >
        <ListItemText
          primary={displayName}
          primaryTypographyProps={{ color: "primary.main" }}
        />
      </ListItemButton>
    </ListItem>
  );
}

interface MobileNavRootMenuItemProps {
  id: string;
  open: boolean;
  url?: string | undefined;
  target?: HTMLAttributeAnchorTarget | undefined;
  displayName: string;
  categories: CategoryMenuItem[];
  libraries: MenuLibraryItem[];
  onExpandClick: MouseEventHandler<HTMLButtonElement>;
}

export function MobileNavRootMenuItem({
  id,
  open,
  url,
  target,
  displayName,
  categories,
  libraries,
  onExpandClick,
}: MobileNavRootMenuItemProps) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:MobileNavRootMenuItem",
  });
  const hasChildren = libraries.length > 0 || categories.length > 0;
  const LibrariesList = libraries.length > 0 && (
    <List disablePadding dense>
      <ListItem sx={{ pl: 4 }}>
        <ListItemText
          primary={t("library")}
          primaryTypographyProps={{ fontWeight: "bold" }}
        />
      </ListItem>
      {libraries.map((library) => (
        <MobileNavLibraryListItem key={library.id} {...library} />
      ))}
    </List>
  );

  if (!hasChildren && !url) {
    return (
      <ListItem data-testid={TEST_ID}>
        <ListItemText
          primary={displayName}
          primaryTypographyProps={{ fontWeight: "bold" }}
        />
      </ListItem>
    );
  }

  if (!hasChildren && url) {
    return (
      <ListItem data-testid={TEST_ID} disablePadding>
        <ListItemButton component={Link} href={url} target={target}>
          <ListItemText
            primary={
              <>
                {displayName}
                <NotificationDot id={id} mode="self" />
              </>
            }
            primaryTypographyProps={{
              fontWeight: "bold",
              color: "primary.main",
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  }

  if (!url) {
    return (
      <>
        <ListItem
          data-testid={TEST_ID}
          secondaryAction={
            <ExpandButton
              data-testid="expand-button"
              open={open}
              onClick={onExpandClick}
            />
          }
        >
          <ListItemText
            primary={
              <>
                {displayName} <NotificationDot id={id} mode="either" />
              </>
            }
            primaryTypographyProps={{ fontWeight: "bold" }}
          />
        </ListItem>

        <Collapse in={open}>
          <Box>
            {LibrariesList}
            {categories.map((props) => (
              <MobileNavCategoryMenuItem key={props.id} {...props} />
            ))}
          </Box>
        </Collapse>
      </>
    );
  }

  return (
    <>
      <ListItem
        data-testid={TEST_ID}
        disablePadding
        secondaryAction={
          <ExpandButton
            data-testid="expand-button"
            open={open}
            onClick={onExpandClick}
          />
        }
      >
        <ListItemButton component={Link} href={url} target={target}>
          <ListItemText
            primaryTypographyProps={{
              color: "primary.main",
              fontWeight: "bold",
            }}
            primary={
              <>
                {displayName}
                <NotificationDot id={id} mode="self" />
              </>
            }
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={open}>
        <Box>
          {LibrariesList}
          {categories.map((props) => (
            <MobileNavCategoryMenuItem key={props.id} {...props} />
          ))}
        </Box>
      </Collapse>
    </>
  );
}
