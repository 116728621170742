import { ReactNode } from "react";
import { usePermissionChecker } from "core/hooks/usePermissionChecker";
import { useRouter } from "next/router";
import { routeAccessMap } from "resources/RouteAccessMap";
import { AvailableRoutes } from "resources/availableRoutes";
import { Unauthorized401Page } from "core/pages/Unauthorized401Page";
import { useBrand } from "core/hooks/useBrand";
import { NotFound404Page } from "core/pages";

export function PagePermissionChecker({ children }: { children: ReactNode }) {
  const { hasSomePermissions, hasAllPermissions } = usePermissionChecker();
  const { pathname } = useRouter();
  const { brandCode } = useBrand();
  const pageConfig = routeAccessMap.get(pathname as AvailableRoutes);
  if (!pageConfig) {
    return <>{children}</>;
  }

  if (pageConfig.disabled) {
    return <Unauthorized401Page />;
  }

  if (
    pageConfig.hasSomePermissions &&
    !hasSomePermissions(pageConfig.hasSomePermissions)
  ) {
    return <Unauthorized401Page />;
  }

  if (
    pageConfig.hasAllPermissions &&
    !hasAllPermissions(pageConfig.hasAllPermissions)
  ) {
    return <Unauthorized401Page />;
  }

  if (
    pageConfig.availableForBrands &&
    !pageConfig.availableForBrands.includes(brandCode)
  ) {
    return <NotFound404Page />;
  }

  return <>{children}</>;
}
