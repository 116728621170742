/* eslint-disable jsx-a11y/anchor-is-valid */
import Divider from "@mui/material/Divider";
import Box, { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import { Link } from "../Link";
import { BrandLogo } from "../BrandLogo";
import { FooterButtons } from "./FooterButtons";
import { FooterLegal } from "./FooterLegal";
import { FooterCategories } from "./FooterCategories";

interface FooterProps extends BoxProps {}

export function Footer({ ...rest }: FooterProps) {
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:Footer" });
  const { isDesktop } = useBreakpoints();
  return (
    <Box component="footer" {...rest} sx={{ displayPrint: "none", ...rest.sx }}>
      <Box
        display="flex"
        component="nav"
        bgcolor="background.paper"
        gap={4}
        sx={{
          py: 2,
          px: 4,
          flexWrap: "wrap",
          "& > *": { flex: 1, minWidth: "min(200px,100%)" },
        }}
      >
        {isDesktop && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                flex: 1,
              }}
              href="/"
            >
              <Box maxWidth={200}>
                <BrandLogo />
              </Box>
              <Typography
                display="flex"
                variant="body2"
                alignItems="center"
                fontWeight={500}
                textTransform="uppercase"
              >
                <ChevronLeft />
                {t("backToHome")}
              </Typography>
            </Link>
            <Divider orientation="vertical" />
          </Stack>
        )}
        <FooterCategories />
        <FooterButtons />
      </Box>
      <FooterLegal />
    </Box>
  );
}
