import { useCallback } from "react";
import { validateResponse } from "core/utils/validateResponse";
import { Paths } from "core/entities/APITypes";
import { useAPIFetch } from "./useAPIFetch";

export function useAPIPostEmpty() {
  const apiFetch = useAPIFetch();
  const post = useCallback(
    async (path: Paths): Promise<Response> => {
      const res = await apiFetch(path, { method: "POST" });
      await validateResponse(res);
      return res;
    },
    [apiFetch]
  );
  return post;
}
