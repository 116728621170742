import { PermissionContext } from "core/entities";
import { useAPI } from "./useAPI";

export function usePermissionContext() {
  return useAPI<PermissionContext[]>(
    "/api/shared-entities/permission-context",
    {
      revalidateIfStale: false,
    }
  );
}
