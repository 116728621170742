import { useRouter } from "next/router";
import { useMemo } from "react";
import { useDmsCategoryLibraryProcessor } from "features/DMS/hooks/useDmsCategoryLibraryProcessor";
import { MenuItemType } from "core/entities";
import { useCentrixApi } from "core/hooks/useCentrixApi";

// APB doesn't allow for undefined url parameter, so we're treating "#" as undefined
export const URL_UNDEFINED_CHARACTER = "#";

export function useMenuItems(menuItemType: MenuItemType = "Header") {
  const { locale } = useRouter();
  const processLibrary = useDmsCategoryLibraryProcessor();

  const { data, isLoading, isError, mutate } = useCentrixApi({
    path: "/api/app/menu-item",
    parameters: {
      query: {
        locale,
      },
    },
    swrOptions: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  });

  const menuItems = useMemo(() => {
    const items = data
      ?.filter((item) => item.menuItemType === menuItemType)
      .map(({ libraries = [], url, submenus, ...rest }) => {
        const processedLibraries = libraries?.map(processLibrary) ?? [];
        const sectionURL = url === URL_UNDEFINED_CHARACTER ? undefined : url;
        const processedSubmenus =
          submenus?.map((category) => {
            const categoryURL =
              category.url === URL_UNDEFINED_CHARACTER
                ? undefined
                : category.url;
            return {
              ...category,
              url: categoryURL,
              submenus: category.submenus,
            };
          }) ?? [];
        return {
          libraries: processedLibraries,
          url: sectionURL,
          submenus: processedSubmenus,
          ...rest,
        };
      });
    return items ?? [];
  }, [data, menuItemType, processLibrary]);
  return { data, isLoading, isError, mutate, menuItems };
}
