import Typography, { TypographyProps } from "@mui/material/Typography";
import type { APIError } from "core/entities";
import { TFunction, useTranslation } from "react-i18next";

export interface ErrorComponentProps extends TypographyProps {
  apiError?: APIError;
  message?: string;
}

export function processErrorMessage(apiError: APIError, t: TFunction) {
  const { status, info, message: genericErrorMessage } = apiError;
  const statusText = status ? `${status}: ` : "";
  const errorCode = info?.error?.code;
  const errorText =
    apiError.info?.error?.message ||
    (errorCode && t(errorCode)) ||
    genericErrorMessage ||
    "";
  return `${statusText}${errorText}`;
}

export function ErrorComponent({
  apiError,
  message,
  ...rest
}: ErrorComponentProps) {
  const { t } = useTranslation();
  if (message) {
    return (
      <Typography variant="h5" color="error.main" {...rest}>
        {message}
      </Typography>
    );
  }
  if (apiError && apiError.message) {
    const apiErrorMessage = processErrorMessage(apiError, t);
    return (
      <Typography variant="h5" color="error.main" {...rest}>
        {apiErrorMessage}
      </Typography>
    );
  }
  return (
    <Typography variant="h3" color="error.main" {...rest}>
      {t("Next:Core:Error")}
    </Typography>
  );
}
